import { setTablePageSize } from "../data/actions/adsTxtActions"
import { Redirect } from "react-router-dom"
import React from "react"
import Wait from "components/Wait/Wait.js"
import _ from "lodash"

export const createNewRoute = (props, target) => {
  props.history.push(target)
}

export const renderRouteBack = (previous) => {
  return <Redirect to={previous}></Redirect>
}

export const renderWait = (state) => {
  return <Wait label={"Loading..."} />
}
export const newId = "new"

export const isNew = (params) => {
  return !params.id || params.id === newId
}
export const changePageSize = (event, dispatch, dataType) => {
  let val = parseInt(event.target.value)
  if (isNaN(val)) val = 0
  dispatch(setTablePageSize(dataType, val))
}

export const defaultOperator = "eq"

export const createQuery = (data) => {
  let query = {}
  for (let [key, value] of Object.entries(data)) {
    if (value && (value.value !== "" || value.operator === "ne")) {
      query[key] = value
    }
  }
  return query
}

export const compareOperators = [
  { value: "sw", label: ">"},
  { value: "eq", label: "="},
  { value: "ne", label: "!="},
  { value: "in", label: "in"},
  { value: "ni", label: "!in"}
]

export const viewLabels = [
  { value: "apps", label: "Apps" },
  { value: "sites", label: "SiteRecords" },
  { value: "publishers", label: "Publishers" },
  { value: "ext_publishers", label: "External Publishers" },
]

export const parseJSON = (str) => {
  try {
    return JSON.parse(str)
  } catch (_) {
    return null
  }
}

export const compareObjs = (a, b) => {
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(i => a[i] === b[i])
}

export const findData = (state, val, key) => {
  return _.get(state, "results", []).find(item => {
    return item[key] === val
  })
}
export const validDomain = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
export const validPubId = /[0-9]{1,11}/
export const validName = /[a-zA-Z0-9_-]{2,45}/
export const validURL = /(\b(https?|ftp|file):\/\/)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/