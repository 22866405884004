import _ from "lodash"

import { domain, IS_DEVELOPMENT } from "../BackendDefs"

const serverUrl = !IS_DEVELOPMENT() 
  ? `wss://${domain}/ws/`
  : `ws://${domain}:3001/ws/`

export const WsEvents = {
  CONNECTED: "connected",
  SUBSCRIBE: "subscribe",
  UNSUBSCRIBE: "unsubscribe",
  REQUEST_STARTED: "request_started",
  REQUEST_COMPLETED: "request_completed",
  REQUEST_ERROR: "request_error",
  HEARTBEAT: "heartbeat"
}

class AdsTxtWs {

  constructor () {
    this.listeners = []
    this.disconnected = true
    this.pingTimeout = null
  }
  
  connect () {
    const token = window.localStorage.getItem('tprToken')
    
    if (!this.ws && token) {
      let url = `${serverUrl}?token=${token}` 
      this.ws = new WebSocket(url)
      this.ws.onmessage = this.onmessage
      this.ws.onclose = this.onclose
      this.ws.onopen = this.onopen
      this.ws.onping= this.onping
    }
   
  }

  disconnect () {
    console.log("disconnect!")
    this.unsubscribe()
    if (this.ws && [0, 1].includes(this.ws.readyState)) {
      this.ws.close(1000, "Logged out")
    }
    this.ws = null
    this.listeners = []
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
      this.pingTimeout = null
    }
  }
  
  subscribe () {
    if (this.disconnected || !this.ws) return
    this.sendEvent(WsEvents.SUBSCRIBE)
  }
  
  unsubscribe () {
    if (this.disconnected || !this.ws) return
    this.sendEvent(WsEvents.UNSUBSCRIBE)
  }

  registerListener (listener) {
    if (!this.listeners.find(listener)) {
      this.listeners.push(listener)
    }
  }

  unregisterListener (listener) {
    if (this.listeners.find(listener)) {
      _.remove(this.listeners, listener)
    }
  }
  
  onmessage = (event) => {
    this.heartbeat()
    const data = JSON.parse(event.data);
    if (data.event) {
      if (data.event === WsEvents.CONNECTED) {
        this.disconnected = false
        this.subscribe()
      }

      this.listeners.forEach(listener => {
        if (listener) listener(data)
      })
    }
  }

  onopen = () => {
    this.heartbeat()
  }
  
  onping = () => {
    this.heartbeat()
  }
  
  onclose = (reason) => {
    this.disconnect()
    this.disconnected = true
  }

  heartbeat = () => {
    clearTimeout(this.pingTimeout)
    if (this.disconnected) return
    
    this.sendEvent(WsEvents.HEARTBEAT)
    // Use `WebSocket#terminate()`, which immediately destroys the connection,
    // instead of `WebSocket#close()`, which waits for the close timer.
    // Delay should be equal to the interval at which your server
    // sends out pings plus a conservative assumption of the latency.
    this.pingTimeout = setTimeout(() => {
      this.disconnect()
    }, 30000 + 1000);
  }
  
  sendEvent = (event, data) => {
    const token = window.localStorage.getItem('tprToken')
    if (token) {
      const msg = {
        token,
        event,
        ...data
      }
      this.ws.send(JSON.stringify(msg))
    }
  }
}

export const adsTxtWs = new AdsTxtWs()