import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import Chip from '@material-ui/core/Chip'
import Table from "components/Table/Table.js"

import styles from "assets/jss/material-dashboard-react/components/filterSelectorStyle.js"
import { makeStyles } from "@material-ui/core/styles"

import _ from "lodash"

const useStyles = makeStyles(styles);

export default function FilterSelector(props) {
  const classes = useStyles()
  const { sspOptions, labelText, value, onChange } = props
  
  let currentValue = value
  
  const onAddFilter = (ssp, type, operator) => {
    let newValue = currentValue.concat([{
      query: {
        ssp: ssp,
        type: type
      },
      logicalOperator: operator
    }])
    onChange(newValue)                                         
  }
  
  const onDeleteFilter = (index) => {
    let newValue = currentValue.filter((item, i) => i !== index)
    onChange(newValue)
  }
  
  const onValueChanged = (field, fieldValue, index) => {
    let newValue = currentValue.map((item, i) => {
      if (i === index) {
        if (field === "logicalOperator") item.logicalOperator = fieldValue
        else item.query[field] = fieldValue
      }
      return item
    })
    onChange(newValue)
  }
  
  const onRenderSelected = (selected) => (
    <div className={classes.chips}>
      {selected && selected.map(val => (
        <Chip key={val} label={val} className={classes.chip} />
      ))}
    </div>
  )
  
  const onRenderOptions = (options) => {
    return options && options.map((item, index) => {
      let value, label
      if (_.isString(item)) {
        value = item
        label = item
      } else {
        value = item.value
        label = item.label
      }
      return <MenuItem key={'menuItem'+index} value={value}>{label}</MenuItem>
    })
  }
  
  return (
    <div>
      <InputLabel
        className={classes.labelRoot}
        htmlFor="filterList"
        shrink={false}>
        {labelText}
      </InputLabel>
      <div id="filterList" className={classes.container}>
        <Table
          className={classes.table}
          tableHeaderColor="primary"
          tableHead={["Operator", "SSP", "Type", "Delete"]}
          tableData={currentValue ? currentValue.map((row, index) => {
            return [
              <Select
                name={"logicalOperatorSelect"}
                value={_.get(row, "logicalOperator", "OR")}
                onChange={(event) => onValueChanged("logicalOperator", event.target.value, index)}
                className={classes.select}
                disabled={!index}
              > {onRenderOptions(["OR", "AND"])} </Select>,
              <Select
                name={"sspSelect"}
                value={_.get(row, "query.ssp", [])}
                onChange={(event) => onValueChanged("ssp", event.target.value, index)}
                className={classes.select}
                input={<Input />}
                renderValue={onRenderSelected}
                multiple
              > {onRenderOptions(sspOptions)} </Select>,
              <Select
                name={"typeSelect"}
                value={_.get(row, "query.type", [])}
                onChange={(event) => onValueChanged("type", event.target.value, index)}
                className={classes.select}
                input={<Input />}
                renderValue={onRenderSelected}
                multiple
              > {onRenderOptions(["direct", "reseller"])} </Select>,
              <Button
                className={classes.deleteButtonList}
                disabled={!index}
                onClick={() => onDeleteFilter(index)}
                color="primary">Delete</Button>
            ]}) : []}
        />
        <Button
          className={classes.addButton}
          onClick={() => onAddFilter(["inmobi.com"], ["reseller"], "OR")}
          color="primary">
          Add
        </Button>
      </div>
    </div>
  )
}

FilterSelector.propTypes = {
  sspOptions: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string
}

