import React, { useEffect } from "react"

// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"

import CustomTabs from "../../components/CustomTabs/CustomTabs"
import ThirdpresenceQuery from "../../components/AppLists/ThirdpresenceQuery"
import CustomQuery from "../../components/AppLists/CustomQuery"

import { useDispatch, useSelector } from "react-redux"
import { getData, DataTypes, setDownloadStarted } from "../../data/actions/adsTxtActions"
import { withRouter } from "react-router-dom"

import { renderWait } from "../common"
import _ from "lodash"
import AsyncOpDialog from "../../components/Dialog/AsyncOpDialog"

const defaultFilters = [
  {
    query: { ssp: ["thirdpresence.com:*"], type: ["direct"] },
    logicalOperator: "OR"
  }
]

const SiteLists = (props) => {
  const state = useSelector(state => state[DataTypes.SITE_LIST])
  const extPubState = useSelector(state => state[DataTypes.EXT_PUBLISHERS])
  const dispatch = useDispatch()
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false)
  const [filters, setFilters] = React.useState(defaultFilters)
  const [currentTab, setCurrentTab] = React.useState()
  
  const downloadLink = React.useRef(null)
  let downloadUrl = null
  const downloadId = _.get(state, "export.download_id")
  
  const onFetchSiteList = () => {
    if (!filters) return
    dispatch(getData(DataTypes.SITE_LIST, { 
      filters: filters
    }, true))
  }
  
  const onTabChanged = (index) => {
    setCurrentTab(index)
  }

  const onQueryChanged = (data, tabIndex) => {
    if (currentTab !== tabIndex) return
    setFilters(data)
  }

  useEffect(() => {
    if (downloadId && !state.downloadStarted) {
      dispatch(setDownloadStarted(DataTypes.SITE_LIST))
      setDownloadDialogOpen(true)
    }
  }, [downloadId, dispatch, state.downloadStarted])
  
  useEffect(() => {
    if (extPubState.results == null && !extPubState.lastError && !extPubState.isFetching) {
      dispatch(getData(DataTypes.EXT_PUBLISHERS, {}))
    }
  }, [extPubState.results, extPubState.lastError, extPubState.isFetching, dispatch])

  const onCloseDownloadDialog = () => {
    setDownloadDialogOpen(false)
  }

  if (!extPubState.results || extPubState.isFetching || state.isFetching) return renderWait(state)

  const buttonArea = (<GridContainer>
    <GridItem  xs={12} sm={12} md={12} style={downloadUrl ? {} : { display: 'none' }}>
      <div>if download does not start in 5 secs press this:
        <a ref={downloadLink} href={downloadUrl}>Download CSV</a>
      </div>
    </GridItem>
    <Button color="primary" onClick={onFetchSiteList}>Fetch site list</Button>
  </GridContainer>)
  
  const thirdPresenceContent = (<div>
    <ThirdpresenceQuery
      extPubsData={extPubState.results}
      onQueryChanged={onQueryChanged}
      filters={defaultFilters}/>
    {buttonArea}
  </div>)

  const customContent = (<div>
    <CustomQuery onQueryChanged={onQueryChanged}/>
    {buttonArea}
  </div>)
  
  return (
    <GridContainer>
      <AsyncOpDialog
        title="Download"
        onClose={onCloseDownloadDialog}
        onDownload={onCloseDownloadDialog}
        isOpen={downloadDialogOpen}
        downloadId={downloadId}
      ></AsyncOpDialog>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title="Fetch Site List"
          headerColor="primary"
          tabs={[
            {
              tabName:    "Thirdpresence",
              tabContent: thirdPresenceContent
            },
            {
              tabName:    "Custom",
              tabContent: customContent
            }
          ]}
          onChange={onTabChanged}
        />
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(SiteLists)
