import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"

const useStyles = makeStyles(styles)

const defaultTitle = "Error occurred"

export default function ErrorDialog(props) {
  const classes = useStyles()
  
  const {
    title,
    errorText,
    onClose,
    isOpen
  } = props

  return (
    <form classes={classes.root}>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title || defaultTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <DialogContentText>
              {errorText}
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

ErrorDialog.propTypes = {
  title: PropTypes.string,
  errorText: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
}
