import { adsTxtApi, DataTypes, Errors } from '../requests/AdsTxtApi'
import { adsTxtWs } from '../requests/AdsTxtWs'

export {
  DataTypes,
  Errors
}

export const Actions = {
  REQUEST_STARTED: "REQUEST_STARTED",
  REQUEST_SUCCEEDED: "REQUEST_SUCCEEDED",
  REQUEST_FAILED: "REQUEST_FAILED",
  ERROR_DISMISSED: "ERROR_DISMISSED",
  PAGE_SIZE_CHANGED: "PAGE_SIZE_CHANGED",
  DOWNLOAD_STARTED: "DOWNLOAD_STARTED",
  INVALIDATE_DATA: "INVALIDATE_DATA",
  CONTEXT_SEARCH: "CONTEXT_SEARCH",
  LOGOUT: "LOGOUT"
}

const onRequestStarted = (dispatch, dataType, params = {}, exportCSV) => {
  dispatch({ type : Actions.REQUEST_STARTED, dataType, query: params.query, export: exportCSV })
}

const onRequestSucceeded = (dispatch, dataType, params = {}, exportCSV, response) => {
  adsTxtWs.connect()
  
  dispatch({ type: Actions.REQUEST_SUCCEEDED, dataType: dataType, data: response, offset: params.offset })
}

const onRequestError = (dispatch, dataType, error) => {
  dispatch({ type : Actions.REQUEST_FAILED, dataType, error })
}

export const logout = () => {
  return (dispatch, getState) => {
    adsTxtApi.logout()
    adsTxtWs.disconnect()
    dispatch({ type : Actions.LOGOUT })
  }
}

export const authenticate = (token) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, "auth")
    adsTxtApi.authorize(token)
      .then(response => onRequestSucceeded(dispatch, DataTypes.AUTH, {}, false, response))
      .catch(error => onRequestError(dispatch,  DataTypes.AUTH, error))
  }
}

export const registerWsListener = (listener) => {
  adsTxtWs.registerListener(listener)
}

export const unregisterWsListener = (listener) => {
  adsTxtWs.unregisterListener(listener)
}

export const dismissError = (error) => {
  return { type : Actions.ERROR_DISMISSED, error }
}

export const getDashboard = (params = {}) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, DataTypes.DASHBOARD, params)

    adsTxtApi.getDashboard()
      .then(response => onRequestSucceeded(dispatch, DataTypes.DASHBOARD, params, false, { result: [] }))
      .catch(error => onRequestError(dispatch, DataTypes.DASHBOARD, error))
  }
}

export const getData = (dataType, params = {}, exportCSV) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, dataType, params, exportCSV)

    adsTxtApi.getData(dataType, params, exportCSV)
      .then(response => onRequestSucceeded(dispatch, dataType, params, exportCSV, response))
      .catch(error => onRequestError(dispatch, dataType, error))
  }
} 

export const createDataItem = (dataType, params, invalidateType) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, dataType)
    
    adsTxtApi.createDataItem(dataType, params)
      .then(response => dispatch({ type: Actions.REQUEST_SUCCEEDED, dataType, data: { result: null } }))
      .then(() => dispatch({ type : Actions.INVALIDATE_DATA, dataType: invalidateType || dataType }))
      .catch(error => onRequestError(dispatch, dataType, error))
  }
}

export const editDataItem = (dataType, params, invalidateType) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, dataType)

    adsTxtApi.editDataItem(dataType, params)
      .then(response => dispatch({ type: Actions.REQUEST_SUCCEEDED, dataType, data: { result: null } }))
      .then(() => dispatch({ type : Actions.INVALIDATE_DATA, dataType: invalidateType || dataType }))
      .catch(error => onRequestError(dispatch, dataType, error))
  }
}

export const deleteDataItem = (dataType, params, invalidateType) => {
  return (dispatch, getState) => {
    onRequestStarted(dispatch, dataType)

    adsTxtApi.deleteDataItem(dataType, params)
      .then(response => dispatch({ type: Actions.REQUEST_SUCCEEDED, dataType: dataType, data: { result: null } }))
      .then(() => dispatch({ type : Actions.INVALIDATE_DATA, dataType: invalidateType || dataType }))
      .catch(error => onRequestError(dispatch, dataType, error))
  }
}

let delayTimer
const CONTEXT_SEARCH_DELAY = 1500
export const contextSearch = (context, query, objectMode = false) => {
  return (dispatch, getState) => {
    dispatch({ type : Actions.CONTEXT_SEARCH, context, loading: true })
    
    if (delayTimer) {
      clearTimeout(delayTimer)
      delayTimer = null
    }
    
    delayTimer = setTimeout(() => {
      adsTxtApi.getData(DataTypes.CONTEXT_SEARCH, { context, query, objectMode: objectMode ? "true" : "false" })
        .then(response => dispatch({ type : Actions.CONTEXT_SEARCH, context, data: response, loading: false }))
        .catch(error => {
          console.log("Context search failed: ", error.message)
          dispatch({ type : Actions.CONTEXT_SEARCH, context, loading: false })
        })
    }, CONTEXT_SEARCH_DELAY)
  }
}

export const setTablePageSize = (dataType, size) => {
  return (dispatch, getState) => {
    dispatch({ type : Actions.PAGE_SIZE_CHANGED, dataType: dataType, size })
  }
}

export const setDownloadStarted = (dataType) => {
  return (dispatch, getState) => {
    dispatch({ type : Actions.DOWNLOAD_STARTED, dataType: dataType })
  }
}

export const invalidateDataType = (dataType) => {
  return (dispatch, getState) => {
    dispatch({ type : Actions.INVALIDATE_DATA, dataType: dataType })
  }
}