import React, { useEffect } from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"
import { DataTypes, contextSearch } from "../../data/actions/adsTxtActions"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

const useStyles = makeStyles(styles);
const contextSearchContext = "developer_names"
  
export default function AddDeveloperDialog(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const state = useSelector(state => state[DataTypes.CONTEXT_SEARCH])
  const inputField = React.useRef()
  const [values, setValues] = React.useState([])
  
  const {
    title,
    contentText,
    onCancel,
    onSubmit,
    isOpen
  } = props

  useEffect(() => {
    if (state && state.context === contextSearchContext && state.values && state.values.length > 0) {
      setValues(state.values)
    }
  }, [state])
  
  const onSelect = () => {
    let selected = _.get(inputField, "current.value")
    let data = values.find(val => val.developer === selected )

    console.log("---", data, selected, state)
    if (data) onSubmit(data.developer, data.site)
  }

  const onRequestData = (value) => {
    if (value && value.length > 2) {
      console.log("onRequestData", value)
      dispatch(contextSearch(contextSearchContext, value, true))
    }
  }
  
  let developerNames = values.map(val => val.developer)
  
  console.log("developerNames", developerNames)
  return (
      <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
          <div className={classes.content}>
            <Autocomplete
              options={developerNames}
              id="debug"
              debug
              loading={state.loading}
              freeSolo
              onInputChange={(_, value) => onRequestData(value)}
              renderInput={params => <TextField {...params} inputRef={inputField} margin="normal" fullWidth />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button disabled={!_.get(inputField, "current.value")} onClick={onSelect} color="primary">
            Select
          </Button>
        </DialogActions>
      </Dialog>
  )
}

AddDeveloperDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}
