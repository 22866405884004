import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Table from "components/Table/Table.js"

import Button from "@material-ui/core/Button"

import { useDispatch, useSelector } from "react-redux"
import {
  createDataItem,
  deleteDataItem,
  getData,
  DataTypes,
  setDownloadStarted
} from "../../data/actions/adsTxtActions"
import { withRouter } from "react-router-dom"

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import AddDeveloperDialog from "./AddDeveloperDialog"
import AddSiteDialog from "./AddSiteDialog"
import _ from "lodash"

const useStyles = makeStyles(styles)

const Developers = (props) => {
  const classes = useStyles()
  const state = useSelector(state => state[DataTypes.DEVELOPERS])
  const appList = useSelector(state => state[DataTypes.APP_INFO_LIST])
  const dispatch = useDispatch()
  const [developerDialogOpen, setDeveloperDialogOpen] = React.useState(false)
  const [siteDialogOpen, setSiteDialogOpen] = React.useState(false)
  
  const downloadLink = React.useRef(null)
  const downloadUrl = _.get(appList, "export.url")
  
  useEffect(() => {
    if (props.id && (state.results == null || state.results.some(item => item.publisher_id !== props.id)) && !state.lastError && !state.isFetching) {
      dispatch(getData(DataTypes.DEVELOPERS, { publisher_id: props.id }))
    }
  }, [state.results, state.lastError, state.isFetching, dispatch, props.id])

  useEffect(() => {
    if (downloadLink.current && downloadUrl && !appList.downloadStarted) {
      dispatch(setDownloadStarted(DataTypes.APP_INFO_LIST))
      downloadLink.current.click()
    }
  }, [downloadLink, downloadUrl, appList.downloadStarted, dispatch])

  const onDelete = (id) => {
    dispatch(deleteDataItem(DataTypes.DEVELOPER_DETAIL, { id }, DataTypes.DEVELOPERS))
  }
  
  const onAddDeveloper = (name, siteUrl, appDeveloper = true) => {
    dispatch(createDataItem(DataTypes.DEVELOPER_DETAIL, {
      publisher_id: props.id,
      name: name,
      site_url: siteUrl,
      app_developer: appDeveloper
    }, DataTypes.DEVELOPERS))
    setDeveloperDialogOpen(false)
    setSiteDialogOpen(false)
  }
  
  const onFetchApps = () => {
    let appDevelopers = state.results.filter(developer => developer.app_developer).map(i => i.name)
    dispatch(getData(DataTypes.APP_INFO_LIST, { developers: appDevelopers }, true))
  }
  
  return (
    <GridContainer>
      <AddDeveloperDialog
        title="Add developer name"
        inputLabel="Developer name"
        contentText="Enter 3 characters to start search"
        onSubmit={onAddDeveloper}
        onCancel={() => setDeveloperDialogOpen(false)}
        isOpen={developerDialogOpen}
      />
      <AddSiteDialog
        title="Add site URL"
        inputLabel="URL"
        contentText="Developer site URL where ads.txt or app-ads.txt is located"
        onSubmit={onAddDeveloper}
        onCancel={() => setSiteDialogOpen(false)}
        isOpen={siteDialogOpen}
      />
      <GridItem xs={12} sm={12} md={12}>
        <FormControl fullWidth >
          <InputLabel
            className={classes.labelRoot}
            htmlFor="developerNames"
            shrink={false}>
            App developer names
          </InputLabel>
          <div id="developerNames" className={classes.container}>
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Site URL", "App developer", "Delete"]}
              tableData={state && state.results ? state.results.map(row => {
                return [
                  row.name,
                  row.site_url,
                  row.app_developer ? "YES" : "NO",
                  <Button 
                    className={classes.deleteButtonList} 
                    disabled={state.isFetching} 
                    onClick={() => onDelete(row.id)} 
                    color="primary">Delete</Button>
                ]}) : []}
            />
            <Button 
              className={classes.addButton} 
              onClick={() => setDeveloperDialogOpen(true)} 
              disabled={!state.results || !props.id || state.isFetching} 
              color="primary">
              Add app developer
            </Button>

            <Button
              className={classes.addButton}
              onClick={() => setSiteDialogOpen(true)}
              disabled={!state.results || !props.id || state.isFetching}
              color="primary">
              Add site
            </Button>
            <Button
              className={classes.addButton} 
              color="primary"
              onClick={onFetchApps}>
              Export app list
            </Button>
            <GridItem  xs={12} sm={12} md={12} style={downloadUrl ? {} : { display: 'none' }} >
              <div>if download does not start in 5 secs press this:
                <a ref={downloadLink} href={downloadUrl}>Download CSV</a>
              </div>
            </GridItem>
          </div>
        </FormControl>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(Developers)
