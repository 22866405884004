import React from "react"

import FormFields from "../Form/FormFields"
import Warning from "../Typography/Warning"

import _ from "lodash"
import { validDomain, validPubId } from "../../views/common"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"

const fields = [
  {
    name: "ssp",
    label: "SSP",
    validation: {
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validDomain,
        message: "Invalid domain"
      }
    }
  },
  {
    name: "publisher_id",
    label: "Publisher id",
    validation: {
      required: 'Required',
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validPubId,
        message: "Invalid publisher id"
      }
    }
  }
]

export default function CustomQuery(props) {
  const useForm1 = useForm({ mode: 'onBlur' })
  const [query, setQuery] = React.useState(
    {
      ssp: "", 
      publisher_id: ""
    }
  )
  
  const onFormChanged = () => {
    let data = useForm1.getValues()
    setQuery(data)
    props.onQueryChanged([{ query: { ssp: `${data.ssp}:${data.publisher_id}`, custom: true } }], 1)
  }
  
  return (
    <form onChange={onFormChanged}>
      <Warning>
        This function uses 42 matters API for fetching the app information. It can be expensive when the result set is large and
        consumes Thirdpresence quota rapidly even the result set is limited to fetch apps for 2000 developer sites. Always consider to use both SSP domain and
        publisher id to limit the result set.
      </Warning>
      <FormFields
        title="Filters"
        data={{ ssp: _.get(query, "ssp", ""), publisher_id: _.get(query, "publisher_id", "")}}
        fields={fields}
        useForm={useForm1}
      />
    </form>
  )
}

CustomQuery.propTypes = {
  onQueryChanged: PropTypes.func.isRequired
}
