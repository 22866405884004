const waitStyle = theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  label: {
    textAlign: 'center',
    padding: '0px',
    margin: '0px'
  }
});

export default waitStyle;
