import React, { useEffect } from "react"
import { useSelector } from "react-redux"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"
import { DataTypes } from "../../data/actions/adsTxtActions"
import _ from "lodash"

const useStyles = makeStyles(styles);

export default function AsyncOpDialog(props) {
  const classes = useStyles()
  const state = useSelector(state => state[DataTypes.DOWNLOADS])
  
  const {
    title,
    contentText,
    onClose,
    onDownload,
    isOpen,
    downloadId
  } = props
  
  const [downloadUrl, setDownloadUrl] = React.useState(null)
  const [error, setError] = React.useState(false)
  const downloadLink = React.useRef(null)
  
  useEffect(() => {
    if (state.results) {
      let item = _.find(state.results, { id: downloadId })
      if (item) {
        if (item.download_url) {
          setDownloadUrl(item.download_url)
        } 
        
        if (item.status === "error") {
          setError(true)
        }
      }
    }
    
  }, [downloadUrl, downloadId, state.downloadStarted, state.results, state.lastError])
  
  const onReady = () => {
    if (downloadLink.current) downloadLink.current.click()
    onDownload()
  }

  return (
    <form classes={classes.root}>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title || "Operation ongoing"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText || "The operation is being processed. " +
              "When ready the download button enables. " +
              "You can also press close and download the result later from Downloads page."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button onClick={onReady} color="primary" disabled={!downloadUrl}>
            {error ? "Failed" : "Download"}
          </Button>
          { !!downloadUrl && 
            <a ref={downloadLink} href={downloadUrl}> </a>
          }
        </DialogActions>
      </Dialog>
    </form>
  )
}

AsyncOpDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  onDownload: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  downloadId: PropTypes.number
}
