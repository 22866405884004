import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Danger from "components/Typography/Danger.js";
import Primary from "components/Typography/Primary.js";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog"

import { Divider } from '@material-ui/core'

import { useDispatch, useSelector } from "react-redux"
import { getData, editDataItem, DataTypes } from "../../data/actions/adsTxtActions"
import { withRouter, useParams } from "react-router-dom"

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import { renderWait } from "../common"

const useStyles = makeStyles(styles)

const SellersJson = (props) => {
  const classes = useStyles();
  const state = useSelector(state => state[DataTypes.SELLERS_JSON])
  const dispatch = useDispatch()
  let params = useParams()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)
  
  useEffect(() => {
    if (!state.results && !state.isFetching && !state.lastError && !state.isPreview) {
      dispatch(getData(DataTypes.SELLERS_JSON))
    }
    return () => {
    }
  }, [state, dispatch, params])

  if ((!state.results && !state.lastError) || state.isFetching) return renderWait(state)
  let currentData = state.results ? state.results : {}

  const onUpdate = () => {
    setConfirmationDialogOpen(false)
    dispatch(editDataItem(DataTypes.SELLERS_JSON, { newVersion: state.results.version}))
  }

  const onGenerateNewSellersJSON = () => {
    setConfirmationDialogOpen(false)
    dispatch(getData(DataTypes.SELLERS_JSON, { preview: true }))
  }
  
  return (
    <GridContainer>
      <ConfirmationDialog
        title="Update confirmation"
        contentText="Are you sure you want to update sellers.json?"
        onConfirm={onUpdate}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Sellers JSON</h4>
          </CardHeader>
          <CardBody>
            <Button color="primary" onClick={() => onGenerateNewSellersJSON()}>Generate new from publisher DB</Button>
            <Button color="primary" disabled={!state.isPreview} onClick={() => setConfirmationDialogOpen(true)}>Update to server</Button>
            <div className={classes.content}>
              {state.isPreview 
                ? <Danger>Preview new version before uploading to the server:</Danger> 
                : <Primary>Current version of thirdpresence.com/sellers.json:</Primary>
              }
              <Divider/>
              <pre>
                {JSON.stringify(currentData, null, 3)}
              </pre>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(SellersJson)
