import rp from "request-promise-native"
import { domain, IS_DEVELOPMENT} from "../BackendDefs"

let baseUrl = !IS_DEVELOPMENT()
  ? `https://${domain}/`
  : `http://${domain}:3001/`

const defaultOptions = {
  limit: 500,
  offset: 0
}

export const Errors = {
  GenericError: "Request failed",
  AuthError: "AUTH_ERROR",
  TimeoutError: "Timeout while processing the request"
}

const commonParams = {
  includeCount: true
}

export const DataTypes = {
  AUTH:                 "auth",
  DASHBOARD:            "dashboard",
  APP_RECORDS:          "app_records",
  APP_DETAIL:           "app",
  APPS:                 "apps",
  CTV_APP_RECORDS:      "ctv_app_records",
  CTV_APP_DETAIL:       "ctv_app",
  CTV_APPS:             "ctv_apps",
  SITE_RECORDS:         "site_records",
  SITE_DETAIL:          "site",
  SITES:                "sites",
  PUBLISHERS:           "publishers",
  PUBLISHER_DETAIL:     "publisher",
  EXT_PUBLISHERS:       "ext_publishers",
  EXT_PUBLISHER_DETAIL: "ext_publisher",
  SAVED_SEARCHES:       "saved_searches",
  SAVED_SEARCH_DETAIL:  "saved_search",
  DEVELOPERS:           "developers",
  DEVELOPER_DETAIL:     "developer",
  DOWNLOADS:            "downloads",
  DOWNLOAD:             "download",
  CONTEXT_SEARCH:       "context_search",
  APP_INFO_LIST:        "app_infos",
  ADS_TXT_RECORDS:      "ads_txt_records",
  APP_LIST:             "app_lists",
  SITE_LIST:            "site_lists",
  CTV_APP_LIST:         "ctv_app_lists",
  SELLERS_JSON:         "sellers_json"
}

class AdsTxtApi {
  
  authorize (token) {
    if (token) {
      window.localStorage.setItem('tprToken', token)
    }
    
    return makeRequest({}, "auth","GET")
  }
  
  logout () {
    window.localStorage.setItem('tprToken', "0")
  }
  
  getDashboard() {
    // TEMP
    return makeRequest({}, "", "GET")
  }

  getData(dataType, params, exportCSV) {
    console.log("getData", dataType, params)
    params = {
      async: isAsync(dataType),
      ...params
    }
    
    return ([
      DataTypes.APP_DETAIL, 
      DataTypes.CTV_APP_DETAIL,
      DataTypes.SITE_DETAIL, 
      DataTypes.SELLERS_JSON].includes(dataType))
        ? makeRequest({}, dataType, "GET", params)
        : makeRequest({...commonParams, ...params, export: exportCSV}, dataType, "POST")
  }

  createDataItem(dataType, params) {
    return makeRequest(params, dataType, "POST")
  }
  
  editDataItem(dataType, params) {
    return makeRequest(params, dataType, "PUT")
  }
  
  deleteDataItem(dataType, params) {
    return makeRequest(params, dataType, "DELETE")
  }
}

async function makeRequest (params, path, method, qs) {
  const token = window.localStorage.getItem('tprToken')
  let res
  try {
    res = await rp({
      method: method || "POST",
      uri:    baseUrl + path,
      json:   true,
      qs:     {
        output: "json",
        token:  token,
        ...qs
      },
      body:   {
        ...defaultOptions,
        ...params
      },
      simple: true
    })
  } catch (e) {
    if (e.statusCode === 401) {
      window.localStorage.setItem('tprToken', "0")
      throw new Error(Errors.AuthError)
    }
    if (e.statusCode === 504) throw new Error (Errors.TimeoutError)
    console.log("Request error", e)
  }
  
  if (!res) throw new Error(Errors.GenericError)
  if (!res.success) throw new Error("Failure: " + (res.error ? res.error : Errors.GenericError) )
  
  return res
}

const isAsync = (dataType) => {
  return [DataTypes.APP_LIST, DataTypes.SITE_LIST, DataTypes.CTV_APP_LIST].includes(dataType)
}

export const adsTxtApi = new AdsTxtApi()