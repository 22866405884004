
const dialogStyle = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  content: {
    width: "400px"
  }
})

export default dialogStyle;
