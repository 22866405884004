import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import TextField from "../Form/TextField"

import { useForm } from "react-hook-form"

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"

const useStyles = makeStyles(styles)

export default function SaveDialog(props) {
  const classes = useStyles()
  const { register, getValues, errors } = useForm({ mode: 'onBlur' })
  
  const {
    title,
    contentText,
    inputLabel,
    onCancel,
    onSubmit,
    isOpen
  } = props
  
  const onSave = (data) => {
    if (data && data.name) onSubmit(data.name)
  }

  return (
    <form classes={classes.root}>
      <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
          <div className={classes.content}>
            <TextField
              label={inputLabel}
              id="name"
              name="name"
              value=""
              register={register}
              validation={{
                required: 'Required',
                maxLength : {
                  value: 45,
                  message: 'Max length exceeded'
                },
                pattern: {
                  value: /[a-zA-Z0-9_-]{2,45}/,
                  message: "Name cannot contain special characters"
                }
              }}
              gridProps={{ xs: 12, sm: 12, md: 12 }}
              errors={errors}
             />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button disabled={errors && !!errors["name"]} onClick={() => onSave(getValues())} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

SaveDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  inputLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}
