import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import FormFields from "components/Form/FormFields"

import { useDispatch, useSelector } from "react-redux"
import { getData, DataTypes } from "../../data/actions/adsTxtActions"
import { withRouter, useParams } from "react-router-dom"

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import moment from "moment"
import { renderWait } from "../common"

const useStyles = makeStyles(styles)

const fields = [
  {
    name: "bundleid",
    label: "App",
    disabled: true
  },
  {
    name: "name",
    label: "Name",
    disabled: true
  },
  {
    name: "store",
    label: "Store",
    disabled: true,
    format: (val) => {
      switch (val) {
        case 1:
          return "Google"
        case 2:
          return "Apple"
        case 3:
          return "Roku"
        case 4:
          return "Amazon"
        default:
          return "N/A"

      }
    }
  },
  {
    name: "site",
    label: "Developer domain",
    disabled: true
  },
  {
    name: "ads_txt_status",
    label: "ads.txt crawler status",
    disabled: true
  },
  {
    name: "app_ads_txt_status",
    label: "app-ads.txt crawler status",
    disabled: true
  },
  {
    name: "updated_at",
    label: "Last updated",
    disabled: true,
    format: (val) => moment.utc(val).format("LLL")
  },
  {
    name: "contact",
    label: "Contact info",
    disabled: true,
    defaultValue: "N/A"
  }
]

const AppDetails = (props) => {
  const ctv = props.location.pathname.startsWith("/admin/ctv")
  const dataType = ctv ? DataTypes.CTV_APP_DETAIL : DataTypes.APP_DETAIL

  const classes = useStyles();
  const state = useSelector(state => state[dataType])
  const dispatch = useDispatch()
  const { history } = props
  let params = useParams()
  
  useEffect(() => {
    const isLoaded = () => (
      state.results && state.results[0] && state.results[0].bundleid === params.bundleid
    )
    
    if (!isLoaded() && !state.isFetching && !state.lastError) {
      dispatch(getData(dataType, { 
        bundleid: params.bundleid
      }))
    }
    return () => {
    }
  }, [state, dispatch, params])

  if ((!state.results && !state.lastError) || state.isFetching) return renderWait(state)
  let currentData = state.results ? state.results[0] || {} : {}

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>App Details</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormFields
                data={currentData}
                fields={fields}
              />
            </form>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={history.goBack}>Close</Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(AppDetails)
