
export const Environments = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development"
}

export const environment = Environments.PRODUCTION

export let domain = null
switch (environment) {
  case Environments.PRODUCTION:
    domain = "adstxt-us.thirdpresence.com"
    break;
  case Environments.STAGING:
    domain = "staging.thirdpresence.com"
    break;
  default:
    domain = "localhost"
}

export const IS_DEVELOPMENT = () => (![Environments.PRODUCTION, Environments.STAGING].includes(environment))
