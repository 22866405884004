import React from "react"
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from '@material-ui/core/TablePagination'
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js"

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles()
  const { tableHead, tableData, tableHeaderColor, pagination } = props;

  let data = tableData
  // fill page with empty
  while (pagination && data.length < pagination.pageSize) {
    data.push(new Array(tableHead.length).fill(""))
  }
  
  return (
    <div className={classes.tableResponsive}>
      <Table className={props.className ? props.className : classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody className={classes.tableBody}>
          {data.map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {prop.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          { pagination &&
          <TableRow className={classes.tableFooterRow}>
            <TablePagination className={classes.tablePagination}
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={12}
              count={pagination.totalCount}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageIndex}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native:     true,
              }}
              onChangePage={pagination.handleChangePage}
              onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
            />
            </TableRow>
            }
        </TableFooter>
      </Table>
    </div>
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
}

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))
}
