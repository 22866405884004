import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js"
import CustomInput from "../../components/CustomInput/CustomInput"
import CustomSelect from "../../components/CustomSelect/CustomSelect"
import { compareOperators, parseJSON } from "../common"
import _ from 'lodash'

const useStyles = makeStyles(styles)

const defaultName = "Unnamed"

export default function SearchEditField(props) {
  const classes = useStyles()
  const { id, label, name, value, gridProps, formControlProps, onChange } = props
  
  const search = parseJSON(value) || {}
  
  const onAddSearch = () => {
    onChange(JSON.stringify({...search, [defaultName]: { operator: "eq", value: "" } }))
  }
  
  const onDeleteSearch = (param) => {
    onChange(JSON.stringify(_.omit(search, param)))
  }

  const onChangeParam = (param, name) => {
    onChange(JSON.stringify({[name]: search[param], ..._.omit(search, param,)}))
  }
  
  const onChangeValue = (param, value, operator) => {
    onChange(JSON.stringify({...search, [param]: { value, operator }}))
  }
  
  return (
      <GridItem key={name} {...gridProps} xs={12} sm={12} md={12}>
        <FormControl
          {...formControlProps}
          className={formControlProps.className + " " + classes.formControl}
        >
          {label !== undefined ? (
            <InputLabel
              className={classes.labelRoot}
              htmlFor={id}
              shrink={true}
            >
              {label}
            </InputLabel>
          ) : null}
          <div className={classes.container}>
            {search && typeof search === "object"
              && Object.keys(search).map((param, index) => {
                return <GridContainer key={"item" + index}>
                  <GridItem xs={12} sm md>
                    <CustomInput
                      labelText="Parameter"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "param",
                        defaultValue: param,
                        onChange: (event) => onChangeParam(param, event.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm md>
                    <CustomSelect
                      labelText="Operator"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={search[param].operator}
                      options={compareOperators}
                      onChange={(event) => onChangeValue(param, search[param].value, event.target.value)}
                      selectProps={{
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm md>
                    <CustomInput
                      labelText="Value"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "value",
                        defaultValue: search[param].value,
                        onChange: (event) => onChangeValue(param, event.target.value, search[param].operator)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={1} md={1}>
                    <Button className={classes.deleteButton} onClick={() => onDeleteSearch(param)} color="primary">
                      Delete
                    </Button>
                  </GridItem>
                </GridContainer>
              }) 
            }
            <Button className={classes.addButton} onClick={onAddSearch} disabled={!!search[defaultName]} color="primary">
            Add
            </Button>
          </div>
        </FormControl>
      </GridItem>
  )
}

SearchEditField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  gridProps: PropTypes.object,
  onChange: PropTypes.func
}
