import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.js";

const filterSelectorStyle = {
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  marginTop: {
    marginTop: "16px"
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset"
  },
  select: {
  },
  container: {
    marginTop: "25px"
  },
  deleteButton: {
    marginTop: "40px"
  },
  deleteButtonList: {
    marginTop: "0"
  },
  addButton: {
    marginTop: "10px"
  },
  table: {
    tableLayout: "fixed",
    width: "100%"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
};

export default filterSelectorStyle;
