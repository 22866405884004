import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"

const useStyles = makeStyles(styles);

export default function ConfirmationDialog(props) {
  const classes = useStyles()
  
  const {
    title,
    contentText,
    onCancel,
    onConfirm,
    isOpen
  } = props
  
  return (
    <form classes={classes.root}>
      <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}
