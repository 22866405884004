import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles)

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    value,
    onChange,
    helperText,
    options,
    selectProps,
  } = props
  
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      <TextField
        id={id}
        select
        label={labelText}
        value={value}
        onChange={onChange}
        helperText={helperText || ""}
        SelectProps={selectProps}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  selectProps: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  helperText: PropTypes.element
}
