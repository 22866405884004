import React, { useCallback, useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from  '@material-ui/core';
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Table from "components/Table/Table.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import FormFields, { FieldTypes } from "components/Form/FormFields"

import { getData, DataTypes } from "../../data/actions/adsTxtActions"
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import { Link, withRouter } from "react-router-dom"
import {
  changePageSize,
  compareOperators,
  createNewRoute,
  createQuery, defaultOperator,
  newId, parseJSON, viewLabels
} from "../common"

const useStyles = makeStyles(styles)

const fields = [
  {
    name: "id",
    label: "Id",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "type",
    label: "Type",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "name",
    label: "Name",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  }
]

const SavedSearches = function (props) {
  const state = useSelector(state => {
    return state[DataTypes.SAVED_SEARCHES]
  })
  const dispatch = useDispatch()
  const classes = useStyles()
  const useForm1 = useForm({ mode: 'onBlur' })

  const onSearch = (data = {}, event, pageIndex = 0, exportCSV = false) => {
    let params = {
      offset: pageIndex * state.pageSize,
      limit: state.pageSize,
      query: createQuery(data)
    }

    dispatch(getData(DataTypes.SAVED_SEARCHES, params, exportCSV))
  }

  let onSearchCallback = useCallback(onSearch, [state])
  
  useEffect(() => {
    if (state.results == null && !state.lastError && !state.isFetching) {
      onSearchCallback()
    }
  }, [state.results, state.lastError, state.isFetching, onSearchCallback])
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Saved Searches
            </h4>
            <div className={classes.progress}>
              { state.isFetching && <LinearProgress color="secondary"/> }
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={useForm1.handleSubmit(onSearch)}>
              <p className={classes.subtitle}>
                Filters
              </p>
              <FormFields
                title="Filters"
                data={state.query}
                fields={fields}
                useForm={useForm1}
                select={{
                  default: defaultOperator,
                  items: compareOperators
                }}
              />
              <GridContainer>
                <GridItem xs={2} sm={2} md={2}>
                  <Button 
                    color="primary" 
                    round
                    type="submit">
                    Search
                  </Button>
                </GridItem>
                <Button
                  color="primary"
                  round
                  onClick={() => createNewRoute(props, `/admin/saved_searches/${newId}`)}>
                  Create
                </Button>
              </GridContainer>
            </form>
            <Table
              tableHeaderColor="primary"
              tableHead={["Id", "Type", "Name", "Search"]}
              tableData={state && state.results ? state.results.map(row => {
                return [
                  <Link to={`/admin/saved_searches/${row.id}`}>{row.id}</Link>,
                  <Link to={`/admin/saved_searches/${row.id}`}>{viewLabels.find(i => i.value === row.type).label}</Link>,
                  <Link to={`/admin/saved_searches/${row.id}`}>{row.name}</Link>,
                  <Link to={{ pathname: `/admin/${row.type}`, searchParams: parseJSON(row.search) || {} }}>Go</Link>
                ]}) : []}
              pagination={{
                totalCount: state.totalCount,
                pageIndex: state.pageIndex,
                pageSize: state.pageSize,
                handleChangePage: (_, page) => onSearch(useForm1.getValues(), null, page),
                handleChangeRowsPerPage: (event) => changePageSize(event, dispatch, DataTypes.SAVED_SEARCHES)
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(SavedSearches)