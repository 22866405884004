import React from "react"

// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"

import PropTypes from "prop-types"
import FilterSelector from "./FilterSelector"

export default function ThirdpresenceQuery(props) {
  
  const onChange = (data) => {
    props.onQueryChanged(data, 0)
  }
  
  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FilterSelector
            labelText="Filters"
            formControlProps={{
              fullWidth: true
            }}  
            
            value={props.filters}
            sspOptions={props.extPubsData.map(item => ({ value: `${item.ssp}:${item.publisher_id}`, label: `${item.ssp} (${item.publisher_id})`}))
              .concat([{ value: `thirdpresence.com:*`, label: `thirdpresence.com (*)`}])}
            onChange={onChange}
            selectProps={{
              multiple: true
            }}
          />
        </GridItem>
      </GridContainer>
    </form>
  )
}

ThirdpresenceQuery.propTypes = {
  onQueryChanged: PropTypes.func.isRequired,
  extPubsData: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired
}
