import { Actions, Errors, DataTypes } from "../actions/adsTxtActions"

const defaultPageSize = 5

const getInitialState = () => {
  let state = {
    [DataTypes.AUTH]: {
      results: null,
      isFetching: false,
      lastError: null
    },
    [DataTypes.CONTEXT_SEARCH]: {
      loading: false
    },
    [DataTypes.APP_INFO_LIST]: {
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    },
    [DataTypes.SELLERS_JSON]: {
      results: null,
      isFetching: false,
      lastError: null,
      isPreview: false
    },
    [DataTypes.ADS_TXT_RECORDS]: {
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    },
    [DataTypes.APP_LIST]: {
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    },
    [DataTypes.SITE_LIST]: {
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    },
    [DataTypes.CTV_APP_LIST]: {
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    },
    errors: [],
    isAuthenticated: true
  }
  
  const listViews = [
    DataTypes.APPS,
    DataTypes.APP_RECORDS, 
    DataTypes.SITES,
    DataTypes.SITE_RECORDS, 
    DataTypes.CTV_APPS,
    DataTypes.CTV_APP_RECORDS,
    DataTypes.PUBLISHERS, 
    DataTypes.DEVELOPERS, 
    DataTypes.SAVED_SEARCHES, 
    DataTypes.EXT_PUBLISHERS,
    DataTypes.DOWNLOADS
  ]
  
  listViews.forEach(view => (
    state[view] = {
      results: null,
      pageIndex: 0,
      pageSize: defaultPageSize,
      totalCount: 0,
      query: {},
      export: null,
      downloadStarted: false,
      isFetching: false,
      lastError: null
    }
  ))

  const detailViews = [
    DataTypes.APP_DETAIL,
    DataTypes.CTV_APP_DETAIL,
    DataTypes.SITE_DETAIL, 
    DataTypes.DOWNLOAD,
    DataTypes.DASHBOARD]
  
  detailViews.forEach(view => {
    state[view] = {
      results:    null,
      isFetching: false,
      lastError:  null
    }
  })
  return state
}

const adsTxtReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case Actions.REQUEST_STARTED:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          results: state[action.dataType] ? state[action.dataType].results : [],
          export: null,
          isPreview: false,
          downloadStarted: false,
          totalCount: 0,
          pageIndex: 0,
          query: action.query || {},
          isFetching: true,
          isError: false,
          lastError: null
        }
      })
    case Actions.REQUEST_SUCCEEDED:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          results: !action.data.export && typeof action.data.result !== "undefined" ? action.data.result : state[action.dataType].results,
          export: action.data.export,
          isPreview: action.data.isPreview,
          totalCount: action.data.totalCount || 0,
          pageIndex: action.offset ? action.offset / state[action.dataType].pageSize : state[action.dataType].pageIndex,
          isFetching: false,
          lastError:  null,
        },
        isAuthenticated: true
      })
    case Actions.REQUEST_FAILED:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          lastError: action.error,
          isFetching: false
        },
        errors: action.error.message !== Errors.AuthError ? state.errors.concat([action.error]) : state.errors,
        isAuthenticated: !action.error || action.error.message !== Errors.AuthError
      })
    case Actions.ERROR_DISMISSED:
      return Object.assign({}, state, {
        errors: state.errors.filter(error => error !== action.error),
      })
    case Actions.CONTEXT_SEARCH:
      return Object.assign({}, state, {
        context_search: {
          context: action.context,
          values: action.data ? action.data.result : [],
          totalCount: action.data ? action.data.totalCount : 0,
          loading: action.loading
        }
      })
    case Actions.PAGE_SIZE_CHANGED:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          pageSize: action.size || defaultPageSize,
          results: null,
          totalCount: 0
        }
      })
    case Actions.DOWNLOAD_STARTED:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          downloadStarted: true
        }
      })
    case Actions.INVALIDATE_DATA:
      return Object.assign({}, state, {
        [action.dataType]: {
          ...state[action.dataType],
          results: null,
          totalCount: 0
        }
      })
    case Actions.LOGOUT:
      return Object.assign({}, state, {
        [DataTypes.AUTH]: {
          ...state[action.dataType],
          results: null,
          isFetching: false,
          lastError: null
        },
        errors: [],
        isAuthenticated: false
      })
    default:
      return state
  }
}

export default adsTxtReducer