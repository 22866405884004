
const commonStyle = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progress: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    marginLeft: "-15px",
    overflow: "hidden"
  },
  tabs: {
    color: "#777",
    width: "100%",
  },
  subtitle: {
    color: "#9c27b0",
    fontSize: "16px"
  },
  content: {
    paddingTop: "10px"
  }
};

export default commonStyle