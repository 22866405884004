import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const customInputStyle = {
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
  select: {
    width: "30%",
    position: "relative",
    right: "-70%",
    top: "45px",
    zIndex: "1"
  }
};

export default customInputStyle;
