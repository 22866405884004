import {
  transition,
  container
} from "assets/jss/material-dashboard-react.js";

const loginStyle = theme => ({
  wrapper: {
    backgroundColor: "black",
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    top: "25%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    minHeight: "calc(100vh - 123px)"
  },
  logoImage: {
    maxWidth: "200px",
    display: "inline-block",
    maxHeight: "120px",
    marginLeft: "10px",
    marginRight: "15px"
  },
  container
});

export default loginStyle;
