import React, { useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"

import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Warning from "components/Typography/Warning.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"

import { useDispatch, useSelector } from "react-redux"
import { getDashboard } from "../../data/actions/adsTxtActions"
import { LinearProgress } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function Dashboard() {
  const classes = useStyles();
  const state = useSelector(state => state.dashboard)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (state.results == null && !state.lastError && !state.isFetching) {
      dispatch(getDashboard())
    }
    return () => {
      // Clean up
    }
  })
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                Dashboard
              </h4>
              <div className={classes.progress}>
                { state.isFetching && <LinearProgress color="secondary"/> }
              </div>
            </CardHeader>
            <CardBody>

            <Warning>Dashboard not yet available. Select another view from the side bar</Warning>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
