import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"

import { ErrorMessage } from "react-hook-form"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import styles from "assets/jss/material-dashboard-react/components/formFieldStyle.js"

const useStyles = makeStyles(styles)

export default function TextField(props) {
  const classes = useStyles()
  const { id, label, name, value, defaultValue, disabled, validation, register, errors, gridProps, select, onChange, endAdornment } = props
  return (
    <GridItem {...gridProps}>
      {select && <Select
        name={name + "select"}
        value={select.value}
        onChange={select.onChange}
        className={classes.select}
      >
        {select.items.map((item, index) => {
          if (!index) return <MenuItem key={'menuItem'+index} value={item.value}><em>{item.label}</em></MenuItem>
          return <MenuItem key={'menuItem'+index} value={item.value}>{item.label}</MenuItem>
        })}
      </Select> }
      <CustomInput
        labelText={label}
        id={id}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          name: name,
          value: value == undefined ? defaultValue : value,
          disabled,
          onChange,
          inputRef: register ? register(validation || {}) : undefined,
          endAdornment: endAdornment
        }}
      />
      {errors && <ErrorMessage errors={errors} name={name} /> }
    </GridItem>
  )
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  gridProps: PropTypes.object,
  select: PropTypes.object,
  onChange: PropTypes.func,
  endAdornment: PropTypes.object
}
