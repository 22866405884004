import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import Navbar from "components/Navbars/Navbar.js"
import Footer from "components/Footer/Footer.js"
import Sidebar from "components/Sidebar/Sidebar.js"

import routes from "routes.js"

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js"

import bgImage from "assets/img/sidebar-bg.jpg"
import {useDispatch, useSelector} from "react-redux"
import ErrorDialog from "../components/Dialog/ErrorDialog"
import {
  DataTypes,
  dismissError,
  getData,
  registerWsListener,
  unregisterWsListener
} from "../data/actions/adsTxtActions"
import { WsEvents } from "../data/requests/AdsTxtWs"

let ps

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      }
      return null
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
)

const useStyles = makeStyles(styles)

export default function Admin({ ...rest }) {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const [isWsRegistered, setWsRegistered] = React.useState(false)
  
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps"
  }
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  const onDismissError = (error) => {
    dispatch(dismissError(error))
  }
  
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = "hidden"
    }
    window.addEventListener("resize", resizeFunction)
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy()
      }
      window.removeEventListener("resize", resizeFunction)
    };
  }, [mainPanel])

  React.useEffect(() => {
    const handleWsEvent = (event) => {
      if (event && (event.event === WsEvents.REQUEST_COMPLETED || event.event === WsEvents.REQUEST_ERROR)) {
        dispatch(getData(DataTypes.DOWNLOADS))
      }
    }
    
    if (!isWsRegistered && state.isAuthenticated) {
      setWsRegistered(true)
      registerWsListener(handleWsEvent)
    }

    return function cleanup() {
      if (isWsRegistered) {
        unregisterWsListener(handleWsEvent)
        setWsRegistered(false)
      }
    }
  }, [isWsRegistered, state, dispatch])

  
  if (!state.isAuthenticated) return (<Redirect to="/login" />)
  
  return (
    <div className={classes.wrapper}>
      { state.errors.length &&
        <ErrorDialog isOpen={true} errorText={state.errors[0].message} onClose={() => onDismissError(state.errors[0])} />}
      <Sidebar
        routes={routes.filter(route => !route.hideFromSidebar)}
        logoText={"Thirdpresence"}
        logo={null}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  )
}
