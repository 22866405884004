import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import FormFields, { FieldTypes } from "components/Form/FormFields"
import ConfirmationDialog from "components/Dialog/ConfirmationDialog"

import { useDispatch, useSelector } from "react-redux"
import {
  createDataItem,
  editDataItem,
  deleteDataItem,
  getData,
  DataTypes,
  setDownloadStarted
} from "../../data/actions/adsTxtActions"
import { useForm } from 'react-hook-form'
import { withRouter, useParams } from "react-router-dom"

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import { isNew, renderRouteBack, renderWait, findData, validPubId, validURL, validDomain } from "../common"
import Developers from "./Developers"
import _ from "lodash"
import moment from "moment"

const useStyles = makeStyles(styles);
const validName = /[a-zA-Z0-9_-]{1,128}/
const now = () => moment.utc().format("YYYY-MM-DD HH:mm:ss")

const fields = [
  {
    name: "publisher_id",
    label: "Publisher id",
    validation: {
      required: 'Required',
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validPubId,
        message: "Invalid publisher id"
      }
    }
  },
  {
    name: "name",
    label: "Company Name (Legal entity)",
    validation: {
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validName,
        message: "Invalid name"
      }
    }
  },
  {
    name: "domain",
    label: "Company domain (for sellers.json)",
    validation: {
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validDomain,
        message: "Invalid domain"
      }
    }
  },
  {
    name: "io_url",
    label: "URL to IO document",
    validation: {
      maxLength : {
        value: 255,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validURL,
        message: "Invalid URL"
      }
    },
    link: true
  },
  {
    name: "type",
    label: "Type",
    type: FieldTypes.SELECT,
    defaultValue: "direct",
    options: [
      { value: "direct", label: "Direct" },
      { value: "reseller", label: "Reseller" },
    ],
  },
  {
    name: "state",
    label: "State",
    type: FieldTypes.SELECT,
    defaultValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
      { value: "DISABLED", label: "Disabled" },
      { value: "ALWAYS_ACTIVE", label: "Always active" },
    ],
  },
  {
    name: "last_active",
    label: "Last Active",
    disabled: true,
    defaultValue: now()
  },
  {
    name: "description",
    label: "Description",
    validation: {
      maxLength : {
        value: 155,
        message: 'Max length exceeded'
      }
    }
  }
]

const PublisherDetails = (props) => {
  const classes = useStyles();
  const state = useSelector(state => state[DataTypes.PUBLISHERS])
  const adsTxt = useSelector(state => state[DataTypes.ADS_TXT_RECORDS])
  const dispatch = useDispatch()
  const useForm1 = useForm()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)
  const { history } = props
  let params = useParams()
  let currentData = {}

  const downloadLink = React.useRef(null)
  const downloadUrl = _.get(adsTxt, "export.url")
  
  useEffect(() => {
    if (state.results == null && !state.lastError && !state.isFetching) {
      dispatch(getData(DataTypes.PUBLISHERS, { id: params.id }))
    }
  }, [state.results, state.lastError, state.isFetching, dispatch, params.id])
  
  const onSubmit = (data) => {
    if (!isNew(params)) {
      dispatch(editDataItem(DataTypes.PUBLISHER_DETAIL, {
        ...currentData,
        ...data,
        last_active: data.state === "ACTIVE" ? now() : currentData.last_active
      }, DataTypes.PUBLISHERS))
    } else {
      dispatch(createDataItem(DataTypes.PUBLISHER_DETAIL, data, DataTypes.PUBLISHERS))
    }
    
    history.goBack()
  }
  
  const onDelete = () => {
    if (!isNew(params)) {
      dispatch(deleteDataItem(DataTypes.PUBLISHER_DETAIL, { id: currentData.id }, DataTypes.PUBLISHERS))
    }
    history.goBack()
  }
  
  const onGetAdsTxt = () => {
    dispatch(getData(DataTypes.ADS_TXT_RECORDS, { publisher_id: currentData.publisher_id, ssp: "thirdpresence.com" }, true ))
  }

  useEffect(() => {
    if (downloadLink.current && downloadUrl && !adsTxt.downloadStarted) {
      dispatch(setDownloadStarted(DataTypes.ADS_TXT_RECORDS))
      downloadLink.current.click()
    }
  }, [downloadLink, downloadUrl, adsTxt.downloadStarted, dispatch])


  if (!isNew(params)) {
    if (!state.results || state.isFetching) return renderWait(state)
    
    currentData = findData(state, parseInt(params.id), 'id')
    if (!currentData) return renderRouteBack('/admin/publishers')
  }

  // Set publisher_id readonly after created
  let modifiedFields = fields.map(field => {
    if (!isNew(params) && field.name === "publisher_id" ) {
      return {
        ...field,
        disabled: true
      }
    }
    return field
  })

  return (
    <GridContainer>
      <ConfirmationDialog
        title="Delete confirmation"
        contentText="Are you sure you want to delete this item?"
        onConfirm={onDelete}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
      />
      <GridItem xs={12} sm={12} md={12}>
        <form onSubmit={useForm1.handleSubmit(onSubmit)}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Publisher</h4>
            </CardHeader>
            <CardBody>
              <FormFields 
                data={currentData}
                fields={modifiedFields}
                useForm={useForm1}
              />
              {!isNew(params) && <Developers id={currentData.id}/> }
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">Submit</Button>
              <Button color="primary" onClick={setConfirmationDialogOpen}>Delete</Button>
              <Button color="primary" disabled={isNew(params)} onClick={onGetAdsTxt}>Get ads.txt records</Button>
              <Button color="primary" disabled={!params.id} onClick={history.goBack}>Close</Button>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
      <GridItem  xs={12} sm={12} md={12} style={downloadUrl ? {} : { display: 'none' }} >
        <div>if download does not start in 5 secs press this:
          <a ref={downloadLink} href={downloadUrl} target="_blank">Download ads.txt records</a>
        </div>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(PublisherDetails)
