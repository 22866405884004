import React, { useEffect, useState } from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton  from "@material-ui/core/IconButton"
import Link from "@material-ui/icons/Link"

import GridContainer from "components/Grid/GridContainer.js"

import TextField from "./TextField"
import SelectField from "./SelectField"
import _ from "lodash"

export const FieldTypes = {
  TEXT: "text",
  TEXT_AND_SELECT: "text+select",
  TEXT_WITH_LINK: "text+link",
  SELECT: "select",
  CUSTOM: "custom"
}

export default function FormFields(props) {
  const { data, fields, useForm, select } = props;
  const [fieldValues, setFieldValues] = useState({})
  
  const ensureValue = (value, defaultVal) => {
    if (value === undefined || value === null) return defaultVal
    return value
  }
  
  const LinkAdornment = (url) => {
    return (<InputAdornment position="end">
      <IconButton
        aria-label="load url"
        disabled={!url}
        onClick={() => { window.open(url, '_blank') }}
      >
        <Link/>
      </IconButton>
    </InputAdornment>)
  }
  
  const TextAndSelectField = (field, val, gridProps, index) => {
    return <TextField
      id={field.name + index}
      key={"sf" + index}
      value={val ? val.value : ""}
      errors={useForm ? useForm.errors : undefined}
      gridProps={gridProps}
      select={{
        value: val ? val.operator : select.default,
        items: select.items,
        onChange: (event) => {
          let newVal = {operator: event.target.value, value: val ? val.value : ""}
          setFieldValues({...fieldValues, [field.name]: newVal})
          useForm.setValue(field.name, newVal)
        }
      }}
      onChange={(event) => {
        let newVal = {operator: val ? val.operator : select.default, value: event.target.value}
        useForm.setValue(field.name, newVal)
        setFieldValues({...fieldValues, [field.name]: newVal})
      }}
      {...field}
    />
  }
  
  const SimpleField = (field, val = "", gridProps, index) => (
    <TextField
      id={field.name + index}
      key={"sf" + index}
      value={field.format ? field.format(val) : val}
      register={useForm ? useForm.register : undefined}
      errors={useForm ? useForm.errors : undefined}
      gridProps={gridProps}
      {...field}
      onChange={(event) => {
        let newVal = event.target.value
        useForm.setValue(field.name, newVal)
        setFieldValues({...fieldValues, [field.name]: newVal})
      }}
      endAdornment={field.link ? LinkAdornment(val) : undefined}
    />
  )
  
  const OptionField = (field, val, gridProps, index) => (
    <SelectField
      id={field.name + index}
      key={"cs" + index}
      value={fieldValues[field.name] || val}
      gridProps={gridProps}
      onChange={(event) => {
        let newVal = event.target.value
        useForm.setValue(field.name, newVal)
        setFieldValues({...fieldValues, [field.name]: newVal })
      }}
      {...field}
    />
  )
  
  const fieldElements = fields.map((field, index) => {
    let val = ensureValue(_.get(data, field.name), field.defaultValue || "")
    let gridProps = field.gridProps || { xs: 12, sm: 12, md: 12 }
    
    switch (field.type) {
      case FieldTypes.SELECT:
        return OptionField(field, val, gridProps, index)
      case FieldTypes.TEXT_AND_SELECT:
        return TextAndSelectField(field, ensureValue(fieldValues[field.name], val), gridProps, index)
      case FieldTypes.TEXT_WITH_LINK:
        return SimpleField(field, ensureValue(fieldValues[field.name], val), gridProps, index)
      case FieldTypes.CUSTOM:
        return field.component({ 
          id: field.name, 
          key: "cf" + field, 
          formControlProps: { fullWidth: true }, 
          ...field, 
          value: fieldValues[field.name] || val,
          onChange: (value) => {
            useForm.setValue(field.name, value)
            setFieldValues({...fieldValues, [field.name]: value })
          }
        })
      default:
        return SimpleField(field, ensureValue(fieldValues[field.name], val), gridProps, index)
    }
  })

  useEffect(() => {
    if (useForm) {
      fields.forEach((field, index) => {
        useForm.register(field.name)
        useForm.setValue(field.name, ensureValue(_.get(data, field.name), field.defaultValue || ""))
      })
    }
  }, [useForm, fields, data])
  
  return (
    <GridContainer>
      {fieldElements}
    </GridContainer>
  )
}

FormFields.propTypes = {
  fields: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  useForm: PropTypes.object
}
