import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormFields from "components/Form/FormFields"

import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js"
import { validURL } from "../common"
import { useForm } from "react-hook-form"

const useStyles = makeStyles(styles);
const validName = /[a-zA-Z0-9_-]{1,128}/

const fields = [
  {
    name: "name",
    label: "Site name",
    validation: {
      maxLength : {
        value: 128,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validName,
        message: "Invalid name"
      }
    }
  },
  {
    name: "site",
    label: "Site URL",
    validation: {
      maxLength : {
        value: 255,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validURL,
        message: "Invalid URL"
      }
    }
  }
]

export default function AddSiteDialog(props) {
  const classes = useStyles()
  const useForm1 = useForm({ mode: 'onBlur' })
  let currentData = {}
  
  const {
    title,
    contentText,
    onCancel,
    onSubmit,
    isOpen
  } = props
  
  const onSelect = () => {
    let result = useForm1.getValues()
    if (result.name && result.site) onSubmit(result.name, result.site, false)
  }

  const { isDirty, isValid } = useForm1.formState;
  
  return (
      <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
          <div className={classes.content}>
            <form onSubmit={useForm1.handleSubmit(onSelect)}>
              <FormFields
                data={currentData}
                fields={fields}
                useForm={useForm1}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onSelect} disabled={!isDirty || !isValid} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
  )
}

AddSiteDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}
