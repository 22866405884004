import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import GridItem from "components/Grid/GridItem.js"
import CustomSelect from "components/CustomSelect/CustomSelect.js"

export default function SelectField(props) {
  const { id, label, name, options, value, disabled, multiple, gridProps, onChange } = props
  return (
    <GridItem {...gridProps}>
      <CustomSelect
        labelText={label}
        id={id}
        formControlProps={{
          fullWidth: true
        }}
        name={name}
        value={Array.isArray(value) ? value : value.split(',')}
        options={options}
        onChange={onChange}
        selectProps={{
          disabled,
          multiple
        }}
      />
    </GridItem>
  )
}

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  validation: PropTypes.object,
  gridProps: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func
}
