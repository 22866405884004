import React, { useCallback, useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from  '@material-ui/core';
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Table from "components/Table/Table.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import FormFields, { FieldTypes } from "components/Form/FormFields"
import SaveDialog from "components/Dialog/SaveDialog"

import {
  getData,
  DataTypes,
  setDownloadStarted,
  createDataItem
} from "../../data/actions/adsTxtActions"

import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import { Link, useLocation } from "react-router-dom"
import _ from 'lodash'
import { changePageSize, compareOperators, defaultOperator, createQuery } from "../common"
import moment from "moment"

const useStyles = makeStyles(styles);

const fields = [
  {
    name: "id",
    label: "Id",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "bundleid",
    label: "Bundle Id",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "dev_site",
    label: "Developer site",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "source",
    label: "Source",
    type: FieldTypes.SELECT,
    defaultValue: "",
    options: [
       { value: "", label: "Any" },
       { value: "PIXALATE_ANDROID_5M", label: "PIXALATE_ANDROID_5M" },
    ],
    gridProps: { xs: 12, sm: 12, md: true }
  },
  {
    name: "app_ads_txt",
    label: "app-ads.txt status",
    type: FieldTypes.TEXT_AND_SELECT,
    gridProps: { xs: 12, sm: 12, md: true }
  }
]

export default function Apps(props) {
  const ctv = props.location.pathname.startsWith("/admin/ctv_status")
  const dataType = ctv ? DataTypes.CTV_APPS : DataTypes.APPS
  const detailRoute = ctv ? "/admin/ctv/" : "/admin/apps/"
  const classes = useStyles()
  const state = useSelector(state => state[dataType])
  const dispatch = useDispatch()
  const { searchParams } = useLocation()
  const useForm1 = useForm({ mode: 'onBlur' })
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false)
  
  const downloadLink = React.useRef(null)
  const downloadUrl = _.get(state, "export.url")

  const onSearch = (data = {}, event, pageIndex = 0, exportCSV = false) => {
    let params = {
      offset: pageIndex * state.pageSize,
      limit: state.pageSize,
      query: createQuery(data)
    }
    
    dispatch(getData(dataType, params, exportCSV))
  }

  const onSaveSearch = (data, name) => {
    setSaveDialogOpen(false)
    if (data && name) {
      dispatch(createDataItem(DataTypes.SAVED_SEARCH_DETAIL, {
        name: name,
        type: dataType,
        search: JSON.stringify(createQuery(data))
      }, DataTypes.SAVED_SEARCHES))
    }
  }

  let onSearchCallback = useCallback(onSearch, [state])
  useEffect(() => {
    if (searchParams) onSearchCallback(searchParams)
  }, [])

  useEffect(() => {
    if (downloadLink.current && downloadUrl && !state.downloadStarted) {
      dispatch(setDownloadStarted(dataType))
      downloadLink.current.click()
    }
  }, [downloadLink, downloadUrl, state.downloadStarted, dispatch])

  useEffect(() => {
    if (state.results == null && !state.lastError && !state.isFetching) {
      onSearchCallback()
    }
  }, [state.results, state.lastError, state.isFetching, onSearchCallback])
  
  return (
    <GridContainer>
      <SaveDialog
        title="Save Search"
        inputLabel="Name"
        contentText="Enter name for the saved search"
        onSubmit={(name) => onSaveSearch(useForm1.getValues(), name)}
        onCancel={() => setSaveDialogOpen(false)}
        isOpen={saveDialogOpen}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Sites Status
            </h4>
            <div className={classes.progress}>
              { state.isFetching && <LinearProgress color="secondary"/> }
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={useForm1.handleSubmit(onSearch)}>
              <p className={classes.subtitle}>
                Filters
              </p>
              <FormFields
                title="Filters"
                data={state.query}
                fields={fields}
                useForm={useForm1}
                select={{
                  default: defaultOperator,
                  items: compareOperators
                }}
              />
              <GridContainer>
                <GridItem xs={2} sm={2} md={2}>
                  <Button
                    color="primary"
                    round
                    type="submit">
                    Search
                  </Button>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Button
                    color="primary"
                    round
                    onClick={() => onSearch(useForm1.getValues(),null, 0, true)}>
                    Export CSV
                  </Button>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Button
                    color="primary"
                    round
                    onClick={() => setSaveDialogOpen(true)}>
                    Save search
                  </Button>
                </GridItem>
                <GridItem  xs={12} sm={12} md={12} style={downloadUrl ? {} : { display: 'none' }} >
                  <div>if download does not start in 5 secs press this:  
                    <a ref={downloadLink} href={downloadUrl}>Download CSV</a>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
            <Table
              tableHeaderColor="primary"
              tableHead={["Id", "Bundle Id","Dev. site", "Source", "app-ads.txt", "Last update"]}
              tableData={state && state.results ? state.results.map(row => {
                return [
                  <Link to={`${detailRoute}${row.bundleid}`}>{row.id}</Link>,
                  <Link to={`${detailRoute}${row.bundleid}`}>{row.bundleid}</Link>,
                  <Link to={`${detailRoute}${row.bundleid}`}>{row.dev_site}</Link>,
                  <Link to={`${detailRoute}${row.bundleid}`}>{row.source}</Link>,
                  <Link to={`${detailRoute}${row.bundleid}`}>{row.app_ads_txt}</Link>,
                  <Link to={`${detailRoute}${row.bundleid}`}>{moment.utc(row.updated_at).format("LLL")}</Link>
                ]}) : []}
              pagination={{
                totalCount: state.totalCount,
                pageIndex: state.pageIndex,
                pageSize: state.pageSize,
                handleChangePage: (_, page) => onSearch(useForm1.getValues(), null, page),
                handleChangeRowsPerPage: (event) => changePageSize(event, dispatch, dataType)
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
