import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import FormFields, { FieldTypes } from "components/Form/FormFields"
import SearchEditField from "./SearchEditField"
import ConfirmationDialog from "components/Dialog/ConfirmationDialog"

import { useDispatch, useSelector } from "react-redux"
import { createDataItem, editDataItem, deleteDataItem, getData, DataTypes } from "../../data/actions/adsTxtActions"
import { useForm } from 'react-hook-form'
import { withRouter, useParams } from "react-router-dom"
import { isNew, renderRouteBack, renderWait, validName, findData, viewLabels } from "../common"

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"

const useStyles = makeStyles(styles)

const fields = [
  {
    name: "id",
    label: "Id",
    disabled: true
  },
  {
    name: "name",
    label: "Name",
    validation: {
      required: "Required",
      maxLength : {
        value: 45,
        message: 'Max length exceeded'
      },
      pattern: {
        value: validName,
        message: "Invalid name"
      }
    }
  },
  {
    name: "type",
    label: "Type",
    type: FieldTypes.SELECT,
    defaultValue: "apps",
    options: viewLabels
  },
  {
    name: "search",
    label: "Search",
    type: FieldTypes.CUSTOM,
    component: SearchEditField
  }
]

const SavedSearchDetails = (props) => {
  const classes = useStyles()
  const state = useSelector(state => state[DataTypes.SAVED_SEARCHES])
  const dispatch = useDispatch()
  const useForm1 = useForm()
  const { history } = props
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)
  let params = useParams()
  let currentData = {}
  
  useEffect(() => {
    if (state.results == null && !state.lastError && !state.isFetching) {
      dispatch(getData(DataTypes.SAVED_SEARCHES, { id: params.id }))
    }
    return () => {
    }
  }, [state.results, state.lastError, state.isFetching, dispatch, params.id])
  
  const onSubmit = (data) => {
    if (!isNew(params)) {
      dispatch(editDataItem(DataTypes.SAVED_SEARCH_DETAIL, {
        ...currentData,
        ...data,
      }, DataTypes.SAVED_SEARCHES))
    } else {
      dispatch(createDataItem(DataTypes.SAVED_SEARCH_DETAIL, data, DataTypes.SAVED_SEARCHES))
    }
    
    history.goBack()
  }
  
  const onDelete = () => {
    if (!isNew(params)) {
      dispatch(deleteDataItem(DataTypes.SAVED_SEARCH_DETAIL, { id: currentData.id }, DataTypes.SAVED_SEARCHES))
    }
    history.goBack()
  }
  
  if (!isNew(params)) {
    if (!state.results || state.isFetching) return renderWait(state)
    
    currentData = findData(state, parseInt(params.id), 'id')
    if (!currentData) return renderRouteBack('/admin/saved_searches')
  }
  
  return (
    <GridContainer>
      <ConfirmationDialog
        title="Delete confirmation"
        contentText="Are you sure you want to delete this item?"
        onConfirm={onDelete}
        onCancel={() => setConfirmationDialogOpen(false)}
        isOpen={confirmationDialogOpen}
      />
      <GridItem xs={12} sm={12} md={12}>
        <form onSubmit={useForm1.handleSubmit(onSubmit)}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Saved Search</h4>
            </CardHeader>
            <CardBody>
              <FormFields 
                data={currentData}
                fields={fields}
                useForm={useForm1}
              />
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">Submit</Button>
              <Button color="primary" onClick={setConfirmationDialogOpen}>Delete</Button>
              <Button color="primary" disabled={!params.id} onClick={history.goBack}>Close</Button>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(SavedSearchDetails)
