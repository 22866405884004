import React from "react"
import PropTypes from "prop-types"
// @material-ui/core components
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
// core components
import styles from "assets/jss/material-dashboard-react/components/waitStyle.js"

const useStyles = makeStyles(styles)

export default function Warning(props) {
  const classes = useStyles()
  const { label } = props
  return (
    <div className={classes.root}>
      <h5 className={classes.label}>{label}</h5>
      <LinearProgress color="secondary" />
    </div>
  )
}

Warning.propTypes = {
  children: PropTypes.node
}
