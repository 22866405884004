import React, { useEffect } from "react"

// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import CustomTabs from "../../components/CustomTabs/CustomTabs"
import CustomQuery from "../../components/AppLists/CustomQuery"
import { useDispatch, useSelector } from "react-redux"
import { getData, DataTypes, setDownloadStarted } from "../../data/actions/adsTxtActions"
import { withRouter } from "react-router-dom"
import { renderWait } from "../common"
import _ from "lodash"
import ThirdpresenceQuery from "../../components/AppLists/ThirdpresenceQuery"
import AsyncOpDialog from "../../components/Dialog/AsyncOpDialog"
import { Switch } from "@material-ui/core"

const defaultFilters = [
  {
    query: { ssp: ["thirdpresence.com:*"], type: ["direct"] },
    logicalOperator: "OR"
  }
]

const AppLists = (props) => {
  const ctv = props.location.pathname.startsWith("/admin/ctvlists")
  const dataType = ctv ? DataTypes.CTV_APP_LIST : DataTypes.APP_LIST
  
  const dispatch = useDispatch()
  const state = useSelector(state => state[dataType])
  const extPubState = useSelector(state => state[DataTypes.EXT_PUBLISHERS])
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false)
  const [filters, setFilters] = React.useState(defaultFilters)
  const [currentTab, setCurrentTab] = React.useState(0)
  const [ipd, setIpd] = React.useState(0)
  const [excludeNoPublisher, setExcludeNoPublisher] = React.useState(0)


  const downloadLink = React.useRef(null)
  let downloadUrl = null
  const downloadId = _.get(state, "export.download_id")
  
  const onFetchAppList = (data) => {
    if (!filters) return
    dispatch(getData(dataType, {
      filters: filters,
      includeIpd: ipd ? true : undefined,
      excludeNoPublisher: excludeNoPublisher ? true : undefined
    }, true))
  }
  
  const onTabChanged = (index) => {
    setCurrentTab(index)
  }
  
  const onQueryChanged = (data, tabIndex) => {
    if (currentTab !== tabIndex) return
    setFilters([...data])
  }
  
  const onCloseDownloadDialog = () => {
    setDownloadDialogOpen(false)
  }
  
  const onIpdChanged = () => {
    setIpd(!ipd)
  }

  const onExcludeNoPublisherChanged = () => {
    setExcludeNoPublisher(!excludeNoPublisher)
  }
  
  useEffect(() => {
    if (downloadId && !state.downloadStarted) {
      dispatch(setDownloadStarted(dataType))
      setDownloadDialogOpen(true)
    }
  }, [downloadId, state.downloadStarted, dispatch])
  
  useEffect(() => {
    if (extPubState.results == null && !extPubState.lastError && !extPubState.isFetching) {
      dispatch(getData(DataTypes.EXT_PUBLISHERS, {}))
    }
  }, [extPubState.results, extPubState.lastError, extPubState.isFetching, dispatch])
  
  if (!extPubState.results || extPubState.isFetching || state.isFetching) return renderWait(state)
  
  const buttonArea = (<GridContainer>
    <GridItem  xs={12} sm={12} md={12} style={downloadUrl ? {} : { display: 'none' }}>
      <div>if download does not start in 5 secs press this:
        <a ref={downloadLink} href={downloadUrl}>Download CSV</a>
      </div>
    </GridItem>
    <GridItem   xs={12} sm={12} md={12} >
    <Switch
      checked={ipd}
      onChange={onIpdChanged}
    /> include IPD records
      <Switch
        checked={excludeNoPublisher}
        onChange={onExcludeNoPublisherChanged}
      /> exclude records no publisher
    </GridItem>
    <Button color="primary" onClick={onFetchAppList}>Fetch app list</Button>
  </GridContainer>)
  
  const thirdPresenceContent = (<div>
    <ThirdpresenceQuery
      extPubsData={extPubState.results}
      onQueryChanged={onQueryChanged}
      filters={filters}
      />
    {buttonArea}
  </div>)
  
  const customContent = (<div>
    <CustomQuery onQueryChanged={onQueryChanged}/>
    {buttonArea}
  </div>)
  
  return (
    <GridContainer>
      <AsyncOpDialog
        title="Download"
        onClose={onCloseDownloadDialog}
        onDownload={onCloseDownloadDialog}
        isOpen={downloadDialogOpen}
        downloadId={downloadId}
      ></AsyncOpDialog>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title="Fetch App List"
          headerColor="primary"
          tabs={[
            {
              tabName:    "Thirdpresence",
              tabContent: thirdPresenceContent
            },
            {
              tabName:    "Custom",
              tabContent: customContent
            }
          ]}
          onChange={onTabChanged}
        />
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(AppLists)
