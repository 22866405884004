import React from "react";
import { Redirect } from "react-router-dom";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js"

import { authenticate, DataTypes } from "../data/actions/adsTxtActions"
import { useSelector, useDispatch } from 'react-redux'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/loginStyle.js";
import bgImage from "assets/img/sidebar-bg.jpg";

import thirdpresence from "assets/img/thirdpresence.png";

const useStyles = makeStyles(styles);

export default function Login({ ...rest }) {
  // styles
  const classes = useStyles();
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  
  const mainPanel = React.createRef();
  const tokenInput = React.createRef();
  
  if (state.isAuthenticated) return (<Redirect to="/" />)
  
  return (
    <div className={classes.wrapper} style={{ backgroundImage: "url(" + bgImage + ")" }} >
      <div className={classes.mainPanel} ref={mainPanel}>
        <GridContainer justify="center">
          <GridItem xs={6}>
            <Card>
              <CardHeader color="primary">
                <img alt="Thirdpresence"
                     className={classes.logoImage} 
                     src={thirdpresence}/>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: "admin"
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Password"
                      id="token"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        inputRef: tokenInput,
                        type: "password"
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <Danger>
                    {state[DataTypes.AUTH].lastError && !state[DataTypes.AUTH].isFetching && "Authentication failed. Check your credentials!"}
                  </Danger>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={() => dispatch(authenticate(tokenInput && tokenInput.current ? tokenInput.current.value : null))}>Login</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
