/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";

// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.js";
import AppRecords from "./views/Apps/AppRecords.js";
import AppDetails from "./views/Apps/AppDetails.js";
import Apps from "./views/Apps/Apps"
import SiteRecords from "./views/Sites/SiteRecords.js";
import SiteDetails from "./views/Sites/SiteDetails.js";
import Sites from "./views/Sites/Sites"
import Publishers from "./views/Publishers/Publishers.js";
import PublisherDetails from "./views/Publishers/PublisherDetails"
import SavedSearches from "./views/SavedSearches/SavedSearches"
import SavedSearchDetails from "./views/SavedSearches/SavedSearchDetails"
import ExternalPublishers from "./views/ExtertnalPublishers/ExternalPublishers"
import ExternalPublisherDetails from "./views/ExtertnalPublishers/ExternalPublisherDetails"
import AppLists from "./views/AppLists/AppLists"
import SiteLists from "./views/SiteLists/SiteLists"
import SellersJson from "./views/SellersJson/SellersJson"
import Downloads from "./views/Downloads/Downloads"

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
//    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/applists",
    name: "Fetch App List",
//    icon: "content_paste",
    component: AppLists,
    layout: "/admin"
  },
  {
    path: "/sitelists",
    name: "Fetch Site List",
//    icon: "content_paste",
    component: SiteLists,
    layout: "/admin"
  },
  {
    path: "/ctvlists",
    name: "Fetch CTV App List",
  //    icon: "content_paste",
    component: AppLists,
    layout: "/admin"
  },
  {
    path: "/publishers",
    name: "Publishers",
//    icon: "content_paste",
    component: Publishers,
    layout: "/admin"
  },
  {
    path:            "/publishers/:id",
    name:            "Publisher",
//    icon: "content_paste",
    component:       PublisherDetails,
    layout:          "/admin",
    hideFromSidebar: true
  },
  {
    path:      "/ext_publishers",
    name:      "External Publisher IDs",
//    icon: "content_paste",
    component: ExternalPublishers,
    layout:    "/admin"
  },
  {
    path:            "/ext_publishers/:id",
    name:            "External Publisher ID",
//    icon: "content_paste",
    component:       ExternalPublisherDetails,
    layout:          "/admin",
    hideFromSidebar: true
  },
  {
    path:      "/sites",
    name:      "Ads.txt Records",
//    icon: "content_paste",
    component: SiteRecords,
    layout:    "/admin"
  },
  {
    path: "/sites/:site",
    name: "Site details",
//    icon: "content_paste",
    component: SiteDetails,
    layout: "/admin",
    hideFromSidebar: true
  },
  {
    path: "/apps",
    name: "App-ads.txt Records",
//    icon: "content_paste",
    component: AppRecords,
    layout: "/admin"
  },
  {
    path: "/ctv",
    name: "CTV app-ads.txt Records",
//    icon: "content_paste",
    component: AppRecords,
    layout: "/admin"
  },
  {
    path: "/ctv/:bundleid",
    name: "App details",
//    icon: "content_paste",
    component: AppDetails,
    layout: "/admin",
    hideFromSidebar: true
  },
  {
    path:      "/sites_status",
    name:      "Sites Status",
//    icon: "content_paste",
    component: Sites,
    layout:    "/admin"
  },
  {
    path:      "/app_status",
    name:      "Apps Status",
//    icon: "content_paste",
    component: Apps,
    layout:    "/admin"
  },
  {
    path:      "/ctv_status",
    name:      "CTV apps Status",
//    icon: "content_paste",
    component: Apps,
    layout:    "/admin"
  },
  {
    path: "/saved_searches",
    name: "Saved Searches",
//    icon: "content_paste",
    component: SavedSearches,
    layout: "/admin"
  },
  {
    path:            "/saved_searches/:id",
    name:            "saved_search",
//    icon: "content_paste",
    component:       SavedSearchDetails,
    layout:          "/admin",
    hideFromSidebar: true
  },
  {
    path: "/sellers_json",
    name: "Sellers.json",
//    icon: "content_paste",
    component: SellersJson,
    layout: "/admin"
  },
  {
    path: "/downloads",
    name: "Downloads",
//    icon: "content_paste",
    component: Downloads,
    layout: "/admin"
  }
]

export default dashboardRoutes;
