import React, { useEffect } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"

import { useDispatch, useSelector } from "react-redux"
import { getData, DataTypes } from "../../data/actions/adsTxtActions"
import { withRouter, useParams } from "react-router-dom"

import styles from "assets/jss/material-dashboard-react/views/commonStyle.js"
import { renderWait } from "../common"
import { LinearProgress } from "@material-ui/core"
import Table from "../../components/Table/Table"

const useStyles = makeStyles(styles)

const Downloads = (props) => {
  const classes = useStyles()
  const state = useSelector(state => state[DataTypes.DOWNLOADS])
  const dispatch = useDispatch()
  let params = useParams()

  useEffect(() => {
    const isLoaded = () => (
      state.results && state.results[0] && state.results[0].site === params.site
    )

    if (!isLoaded() && !state.isFetching && !state.lastError) {
      dispatch(getData(DataTypes.DOWNLOADS, 
        {
          site: params.site,
          orderBy: 'id DESC'
        }))
    }
    return () => {
    }
  }, [state, dispatch, params])

  if ((!state.results && !state.lastError) || state.isFetching) return renderWait(state)
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Downloads</h4>
            <div className={classes.progress}>
              { state.isFetching && <LinearProgress color="secondary"/> }
            </div>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Date", "Name", "Status", "Download"]}
              tableData={state && state.results ? state.results.map(row => {
                return [
                  row.date,
                  row.name,
                  row.status,
                  row.status === "ready"
                  ? <a href={row.download_url}>Download</a>
                  : ""
                ]}) : []}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withRouter(Downloads)
